import { AxiosRequestConfig } from "axios";

import { Configuration } from "./configuration";

export const getUrl = (
  path: string,
  queryParams: any = {},
  options: AxiosRequestConfig,
  configuration?: Configuration,
) => {
  const urlObj = new URL(path, configuration?.basePath);
  const query = new URLSearchParams(urlObj.search);
  for (const key in queryParams) {
    query.set(key, queryParams[key]);
  }
  for (const key in options.params) {
    query.set(key, options.params[key]);
  }
  urlObj.search = new URLSearchParams(query).toString();

  return urlObj.pathname + urlObj.search + urlObj.hash;
};

export const getRequestConfig = async (
  method: "GET" | "POST" | "PUT" | "DELETE",
  options: AxiosRequestConfig,
  configuration?: Configuration,
  body?: string | { [key: string]: string },
) => {
  const baseOptions = configuration?.baseOptions;
  const requestOptions: AxiosRequestConfig = {
    method: method,
    ...baseOptions,
    ...options,
  };

  const headerParams = {} as any;

  // x-api-key
  if (configuration && configuration.apiKey) {
    const apiKey =
      typeof configuration.apiKey === "function"
        ? await configuration.apiKey("X-API-KEY")
        : await configuration.apiKey;
    headerParams["X-API-KEY"] = apiKey;
  }

  // bearer
  if (configuration?.accessToken) {
    const accessToken = await configuration.accessToken;
    headerParams["Authorization"] = `Bearer ${accessToken}`;
  }

  headerParams["Content-Type"] = "application/json";
  const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
  requestOptions.headers = {
    ...headerParams,
    ...headersFromBaseOptions,
    ...options.headers,
  };

  if (body) {
    const needsSerialization =
      typeof body !== "string" || requestOptions.headers!["Content-Type"] === "application/json";
    requestOptions.data = needsSerialization
      ? JSON.stringify(body !== undefined ? body : {})
      : body || "";
  }

  return requestOptions;
};
