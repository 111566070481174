import React from "react";

import { clsMerge } from "@/utils/cls-merge";

interface Props {
  className?: string;
  children?: any;
  el?: HTMLElement;
  isHeader?: boolean;
  withFullWidth?: boolean;
}

const Container: React.FC<Props> = ({
  children,
  className,
  el = "div",
  isHeader = false,
  withFullWidth = false,
}) => {
  const rootClassName = clsMerge("px-[15px] md:px-10 max-w-supported mx-auto", className);

  const Component: React.ComponentType<React.HTMLAttributes<HTMLDivElement>> = el as any;

  return <Component className={rootClassName}>{children}</Component>;
};

export default Container;
