import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { Key } from "swr/dist/_internal";
import useSWRMutation from "swr/mutation";

import { BASE_EXTERNAL_PATH } from "@/utils/api/base";
import { getConfiguration } from "@/utils/api/configuration";
import { ApiFactory } from "@/utils/api/general";

type CheckoutBody = {
  walletAddress?: `0x${string}`;
  customName: string;
};

const createPaymentSession = async (url: string, JWT: string, body: CheckoutBody) => {
  const config = getConfiguration(BASE_EXTERNAL_PATH, JWT);
  try {
    const { data } = await ApiFactory(config).createPaymentSession(url, body);
    return data;
  } catch (err) {
    throw "Service unavailable. Please try again later";
  }
};

const useCreatePaymentSession = () => {
  const { getAccessTokenSilently } = useAuth0();

  const fetcher = useCallback(
    async (url: string, { arg }: { arg: any }) => {
      let token = "";
      try {
        token = await getAccessTokenSilently();
      } catch (err) {
        // probably not logged in, allow stripe checkout without logging in
        console.error(err);
      }
      return createPaymentSession(url, token, arg);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { data, trigger, error, isMutating } = useSWRMutation<any, any, Key, CheckoutBody>(
    "/api/checkout/session",
    fetcher,
  );

  return { data, trigger, error, isMutating };
};

export default useCreatePaymentSession;
