import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import zod from "zod";

import { AppButton } from "../Button";

import useMixAuthenticated from "@/hooks/useMixAuthenticated";
import { useCloseModal } from "@/hooks/useModal";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond, inter } from "@/utils/font";
import { gtmCustomEvent } from "@/utils/gtm";


enum Status {
  PaymentProcessed,
  ItemDelivered,
}

const getNftDetails = async (txId: string) => {
  const res = await axios.get("/api/assets/details", {
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      txId,
    },
  });
  return res.data;
};

const auth0sendEmailTo = async (email: string, token: string) => {
  const res = await axios.post(
    "/api/user/send-email-stripe",
    { email },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return res.data;
};

const metamaskSendEmailTo = async (email: string, address: `0x${string}`) => {
  const res = await axios.post(
    "/api/user/email",
    { email, address },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return res.data;
};

const StripeTransactionInProgress = ({ dbTxId }: { dbTxId: string }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { address } = useAccount();
  const [status, setStatus] = useState(Status.PaymentProcessed);
  const router = useRouter();
  const closeModal = useCloseModal();
  const { user } = useMixAuthenticated();
  const [email, setEmail] = useState(user?.email);
  const [emailError, setEmailError] = useState("");
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Need this as auth0 takes a bit of time to resolve
    if (user?.contact_email || user?.email) {
      setEmail(user?.contact_email || user?.email);
    }
  }, [user?.email]);

  useEffect(() => {
    const itemDelivered = setTimeout(() => {
      setStatus(Status.ItemDelivered);
    }, 4500);

    return () => {
      clearTimeout(itemDelivered);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      const currentPath = router.asPath;
      if (as !== currentPath) {
        if (confirm("Are you sure?")) {
          closeModal();
          return false;
        } else {
          window.history.pushState(null, "", currentPath);
          return false;
        }
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  return (
    <div
      className={clsMerge(
        EBGaramond.className,
        "relative mx-auto h-full w-screen overflow-y-scroll",
      )}
    >
      <div className="mx-auto mt-[10%] h-[500px] w-full">
        <div
          className={clsMerge(
            "flex items-center justify-center gap-6 px-6",
            status === Status.PaymentProcessed && "pb-40",
            status === Status.ItemDelivered && "pb-20",
          )}
        >
          <div className="inline-flex w-fit flex-col items-center justify-center gap-4 rounded-[100px]">
            <div className="inline-flex items-center justify-center gap-4">
              <div
                className={clsMerge(
                  "flex h-[32px] w-[32px] items-center justify-center rounded-[100px]",
                  status >= 0 && "bg-titanic-blue",
                  status < 0 && "ring-2 ring-inset ring-zinc-500",
                )}
              >
                <svg
                  className={clsMerge("h-6 w-6 stroke-white stroke-[3px]", status < 0 && "hidden")}
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  viewBox="0 0 24 24"
                >
                  <path d="M4.5 12.75l6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className="theme-small-text1 w-max text-center text-white">Payment processed</div>
          </div>
          <div className="mb-8 h-0.5 w-[120px] bg-titanic-blue " />
          <div className="inline-flex w-fit flex-col items-center justify-center gap-4 rounded-[100px]">
            <div className="inline-flex items-center justify-center gap-4">
              <div
                className={clsMerge(
                  "flex h-[32px] w-[32px] items-center justify-center rounded-[100px]",
                  status >= 1 && "bg-titanic-blue",
                  status < 1 && "ring-2 ring-inset ring-zinc-500",
                )}
              >
                <svg
                  className={clsMerge("h-6 w-6 stroke-white stroke-[3px]", status < 1 && "hidden")}
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  viewBox="0 0 24 24"
                >
                  <path d="M4.5 12.75l6 6 9-13.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className="theme-small-text1 w-max text-center text-white">
              {status < 1 ? "Delivering item" : "Item delivered"}
            </div>
          </div>
        </div>
        {status === 0 && (
          <section className={clsMerge("mx-auto w-full text-white lg:max-w-[588px]")}>
            <div className="flex items-center justify-center self-center text-2xl font-semibold uppercase">
              Purchase in progress
            </div>{" "}
            <p className="theme-body2 md:theme-body1 mx-auto mt-6 px-4 text-center text-lg font-normal">
              We{"'"}re delivering your item. Please do not refresh the page or exit out of the
              window during this process.
            </p>
          </section>
        )}

        {status === 1 && (
          <section className={clsMerge("mx-auto w-full gap-y-4 text-white lg:max-w-[588px]")}>
            <div className="mt-4 flex items-center justify-center self-center text-2xl font-semibold uppercase">
              Item Delivered
            </div>
            <p
              className={clsMerge(
                inter.className,
                "theme-body2 md:theme-body1 mx-auto mt-10 px-4 text-center font-normal",
              )}
            >
              Congratulations! Your item is now available in My Collection. To get your purchase
              receipt and stay updated on membership benefits like Merchandise updates and access to
              Expedition content, please share your email with us.
            </p>

            <input
              className={clsMerge(
                "mx-4 mt-10 inline-flex h-14 w-full items-center justify-start gap-2 bg-[#191919] px-4 py-2 placeholder:text-[#87888C]",
                "theme-body2 md:theme-body1 z-50 font-normal focus-within:outline-none focus:outline-none",
                inter.className,
              )}
              placeholder="Email"
              ref={emailInputRef}
              type="email"
              value={email}
              onChange={e => {
                setEmailError("");
                setEmail(e.target.value);
              }}
            />
            {!!emailError && (
              <div
                className={clsMerge("mx-4 mt-4 text-start text-sm text-red-400", inter.className)}
              >
                {emailError}
              </div>
            )}
            <div className="mt-[32px] flex w-full flex-1 justify-center">
              <AppButton
                size="large"
                variant="contained"
                onClick={async () => {
                  const schema = zod.string().email();

                  if (!email || email.trim() === "") {
                    setEmailError("Enter your email");
                    if (emailInputRef?.current) emailInputRef.current.focus();
                    return;
                  }

                  if (email) {
                    const parsed = schema.safeParse(email);
                    if (parsed.success) {
                      setEmailError("");
                      if (isAuthenticated) {
                        await auth0sendEmailTo(email, await getAccessTokenSilently());
                        gtmCustomEvent({
                          action: "fill_email_after_payment",
                        });
                      } else if (address) {
                        gtmCustomEvent({
                          action: "fill_email_after_payment",
                        });
                        await metamaskSendEmailTo(email, address);
                      } else {
                        console.error(
                          `Error fail to send email email:${email} for dbTxId:${dbTxId}`,
                        );
                        gtmCustomEvent({
                          action: `fail to send email email:${email} for dbTxId:${dbTxId}`,
                        });
                      }
                      closeModal();
                      if (dbTxId) {
                        const detail = await getNftDetails(dbTxId);
                        if (detail) {
                          router.push(`/my-collection/${dbTxId}`);
                        } else {
                          router.push(`/my-collection`);
                        }
                      } else {
                        router.push(`/my-collection`);
                      }
                    } else {
                      setEmailError(
                        "Invalid email address. Please check your input and try again.",
                      );
                      if (emailInputRef?.current) emailInputRef.current.focus();
                    }
                  } else {
                    closeModal();
                    if (dbTxId) {
                      const detail = await getNftDetails(dbTxId);
                      if (detail) {
                        router.push(`/my-collection/${dbTxId}`);
                      } else {
                        router.push(`/my-collection`);
                      }
                    } else {
                      router.push(`/my-collection`);
                    }
                  }
                }}
              >
                OK
              </AppButton>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default StripeTransactionInProgress;
