import { useAuth0 } from "@auth0/auth0-react";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { FunctionComponent, useEffect, useState } from "react";
import { useAccount } from "wagmi";

import useCreatePaymentSession from "@/hooks/useCreatePaymentSession";
import useMixAuthenticated from "@/hooks/useMixAuthenticated";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY!);

const getUserWalletAddress = async (token: string) => {
  const res = await axios.get("/api/user/wallet-address", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

const StripeEmbeddedCheckout: FunctionComponent<{ customName: string }> = ({ customName }) => {
  const { loading } = useMixAuthenticated();
  const { getAccessTokenSilently, isAuthenticated: auth0IsAuthenticated } = useAuth0();
  const { address } = useAccount();
  const [clientSecretEmbedded, setClientSecretEmbedded] = useState(null as string | null);

  const { trigger, data } = useCreatePaymentSession();

  useEffect(() => {
    const action = async () => {
      try {
        let walletAddress = address;
        if (auth0IsAuthenticated) {
          const token = await getAccessTokenSilently();
          const userWalletInfo = await getUserWalletAddress(token);
          walletAddress = userWalletInfo.walletAddress; // Override with user's wallet address if authenticated
        }
        const triggerPayload = { customName, ...(walletAddress ? { walletAddress } : {}) };
        trigger(triggerPayload);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    if (!loading) {
      action();
    }
  }, [trigger, loading]);

  useEffect(() => {
    if (data && data.clientSecret && !clientSecretEmbedded) {
      setClientSecretEmbedded(data.clientSecret);
    }
  }, [clientSecretEmbedded, data]);

  return (
    <div className="h-full overflow-y-scroll" id="checkout">
      {clientSecretEmbedded && (
        <EmbeddedCheckoutProvider
          options={{ clientSecret: clientSecretEmbedded }}
          stripe={stripePromise}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default StripeEmbeddedCheckout;
