/* eslint-disable react/no-multi-comp */

import ArtifactLabsLogo from "@public/artifactlabs_logo_4x.png";
import TitanicLogo from "@public/rms_titanic_logo_2x.png";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import AppLink from "@/components/AppLink";
import { DynamicConnectButton } from "@/components/Button/dynamics";
import Container from "@/components/Layout/Container";
import { useOpenDrawer } from "@/hooks/useDrawer";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

const variants = {
  header: {
    initial: {
      opacity: 0,
      y: -100,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    transition: {
      ease: "easeInOut",
      duration: 0.6,
    },
    viewport: {
      once: true,
    },
  },
};

const links = [
  { name: "board now", href: "/board-now", className: "ga-click-board-now-navigation" },
  { name: "benefits", href: "/benefits" },
  { name: "community", href: "/#community-wall" },
];

const NavLink: React.FunctionComponent = () => {
  return (
    <>
      <div className={clsMerge("flex capitalize", "gap-x-10")}>
        {links.map((each, index) => (
          <AppLink className={each.className ?? ""} href={each.href} key={`${index}-${each.name}`}>
            {each.name}
          </AppLink>
        ))}
      </div>
    </>
  );
};

const MobileMenu = ({
  handleOnClick,
}: {
  handleOnClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <div className="ml-[24px] flex lg:hidden">
      <button
        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
        type="button"
        onClick={handleOnClick}
      >
        <span className="sr-only">Open main menu</span>
        <div className="space-y-2">
          <div className="h-[2px] w-[25px] bg-white" />
          <div className="h-[2px] w-[25px] bg-white" />
          <div className="h-[2px] w-[25px] bg-white" />
        </div>
      </button>
    </div>
  );
};

const Header: React.FunctionComponent = () => {
  const openDrawer = useOpenDrawer();

  return (
    <motion.header
      {...variants.header}
      className={clsMerge(
        EBGaramond.className,
        "fixed inset-x-0 top-0 z-50",
        "w-full max-w-full",
        "bg-app-header-gradient backdrop-blur-sm",
      )}
    >
      <Container aria-label="navbar" className="py-5" isHeader>
        <nav className="flex items-center justify-between">
          <section className="flex min-w-[200px] gap-x-6">
            <Link className={clsMerge("flex animate-fade-in items-center justify-center")} href="/">
              <Image
                alt="titanic logo"
                className="h-[43px] w-[97px] xl:h-[56px] xl:w-[126px]"
                src={TitanicLogo}
                unoptimized
              />
            </Link>
            <Link className={clsMerge("flex animate-fade-in items-center justify-center")} href="/">
              <Image
                alt="artifactlabs logo"
                className="h-[40px] w-[38px] xl:h-[56px] xl:w-[56px]"
                src={ArtifactLabsLogo}
                unoptimized
              />
            </Link>
          </section>
          <div className="hidden flex-1 items-center justify-center lg:flex">
            <NavLink />
          </div>
          <div className="hidden min-w-[200px]  justify-end lg:flex">
            <DynamicConnectButton />
          </div>
          <MobileMenu
            handleOnClick={() => {
              openDrawer();
            }}
          />
        </nav>
      </Container>
    </motion.header>
  );
};

export default Header;
