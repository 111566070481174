/* eslint-disable react/no-multi-comp */
import { Dialog, Transition } from "@headlessui/react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { useCloseDrawer } from "@/hooks/useDrawer";
import useMixAuthenticated from "@/hooks/useMixAuthenticated";
import UIAtom from "@/recoil/UI";

const HomeMenuMobile = dynamic(() => import("@/components/HomeMenuMobile"));

const links = [
  { name: "homepage", href: "/" },
  { name: "board now", href: "/board-now", className: "ga-click-board-now-navigation" },
  { name: "benefits", href: "/benefits" },
  { name: "community", href: "/#community-wall" },
];

const DrawerView: React.FC<{
  open: boolean;
  drawerView: string;
  closeDrawer: (...args: any) => void;
}> = ({ open, drawerView, closeDrawer }) => {
  const { isAuthenticated, isWeb3Connected } = useMixAuthenticated();
  const isLogin = useMemo(
    () => isAuthenticated || isWeb3Connected,
    [isAuthenticated, isWeb3Connected],
  );

  return (
    <Transition.Root as={React.Fragment} show={open}>
      <Dialog as="div" open={open} onClose={closeDrawer}>
        <div className="fixed inset-0 z-60 overflow-y-auto">
          {drawerView === "HOME_NAVIGATION" && (
            <HomeMenuMobile
              navigation={
                isLogin ? [...links, { name: "my collection", href: "/my-collection" }] : links
              }
            />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const Drawer: React.FC = () => {
  const UI = useRecoilValue(UIAtom);
  const closeDrawer = useCloseDrawer();

  return (
    <DrawerView closeDrawer={closeDrawer} drawerView={UI.drawerView} open={UI.displayDrawer} />
  );
};

export default Drawer;
