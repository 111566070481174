import ReactLenis from "@studio-freight/react-lenis";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import dynamic from "next/dynamic";
import React from "react";

import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import Drawer from "@/components/Layout/Drawer";
import Modal from "@/components/Layout/Modal";
import { clsMerge } from "@/utils/cls-merge";

const ToastPortal = dynamic(() => import("@/components/Layout/ToastPortal"), {
  ssr: false,
});

const queryClient = new QueryClient();

interface Props {
  withLogo: boolean;
  showSubscribeNewsletter?: boolean;
  pageProps?: {
    pages?: Array<any>;
  };
  children?: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <ReactLenis
        options={{
          lerp: 0.3,
        }}
        root
      >
        <QueryClientProvider client={queryClient}>
          <Header />
          <main className={clsMerge("min-h-screen")}>{children}</main>
          <Footer />
          <Modal />
          <Drawer />
        </QueryClientProvider>
      </ReactLenis>
      <ToastPortal />
    </>
  );
};

export default MainLayout;
