import { atom } from "recoil";

export interface State {
  displayModal: boolean;
  displayDrawer: boolean;
  modalView: string;
  drawerView: string;
  withModalBackdrop: boolean;
  modalProps: any;
  isClosable: boolean;
}

const initialState: State = {
  displayModal: false,
  displayDrawer: false,
  modalView: "INVITATION_FORM",
  drawerView: "HOME_NAVIGATION",
  withModalBackdrop: true,
  isClosable: true,
  modalProps: {},
};

const UIAtom = atom({
  key: "UIAtom",
  default: initialState,
});

export default UIAtom;
