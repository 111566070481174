import { Dialog, Transition } from "@headlessui/react";
// TODO fix eslint
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import dynamic from "next/dynamic";
import React from "react";
import { useRecoilValue } from "recoil";

import { CheckOutModalView } from "./CheckOutModalView";

import Loading from "@/components/common/Loading";
import LoginModal from "@/components/Modal/LoginModal";
import StripeTransactionInProgress from "@/components/Modal/StripeTransactionInProgressModal";
import TransactionInProgressModal from "@/components/Modal/TransactionInProgressModal";
import PhNargeolet from "@/components/PhNargeolet";
import { useCloseModal } from "@/hooks/useModal";
import UIAtom from "@/recoil/UI";
import { clsMerge } from "@/utils/cls-merge";

const dynamicProps = {
  loading: Loading,
};

const InvitationForm = dynamic(() => import("@/components/InvitationForm"), {
  ...dynamicProps,
});

const ModalView: React.FunctionComponent<{
  open: boolean;
  modalView: string;
  closeModal: (...args: any) => void;
  withModalBackdrop: boolean;
  modalProps: any;
  isClosable?: boolean;
}> = ({ open, modalView, closeModal, withModalBackdrop, modalProps, isClosable = true }) => {
  return (
    <Transition.Root as={React.Fragment} show={open}>
      <Dialog
        as="div"
        className="z-70"
        open={open}
        onClose={() => undefined} // set to undefined because we use a custom full-screen backdrop
      >
        {withModalBackdrop && (
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              className={clsMerge(
                "fixed inset-0  z-50 bg-gradient-to-b from-[#1215244D] to-[#00000066] backdrop-blur transition-opacity",
              )}
              disabled={!isClosable}
              onClick={() => {
                return isClosable && closeModal();
              }}
            />
          </Transition.Child>
        )}

        <div className="fixed inset-0 z-60 mx-auto flex w-fit items-center justify-center overflow-y-auto overflow-x-hidden">
          {modalView === "INVITATION_FORM" && <InvitationForm />}
          {modalView === "LOGIN" && <LoginModal {...modalProps} />}
          {modalView === "PH_NARGEOLET" && <PhNargeolet />}
          {modalView === "CHECKOUT_STRIPE" && <CheckOutModalView {...modalProps} />}
          {modalView === "TRANSACTION_IN_PROGRESS" && (
            <TransactionInProgressModal {...modalProps} />
          )}
          {modalView === "STRIPE_TRANSACTION_IN_PROGRESS" && (
            <StripeTransactionInProgress {...modalProps} />
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
};

// eslint-disable-next-line react/no-multi-comp
const Modal: React.FunctionComponent = () => {
  const UI = useRecoilValue(UIAtom);
  const closeModal = useCloseModal();

  return (
    <ModalView
      closeModal={closeModal}
      isClosable={UI.isClosable}
      modalProps={UI.modalProps}
      modalView={UI.modalView}
      open={UI.displayModal}
      withModalBackdrop={UI.withModalBackdrop}
    />
  );
};

export default Modal;
